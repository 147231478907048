import React, { useEffect, useState } from "react";
import { Button, Table, TablePaginationConfig, Tag } from "antd";

import { listCrawlings } from "../../services";
import * as _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { FilterValue, SortOrder, SorterResult } from "antd/lib/table/interface";
import { ICrawling } from "~/model/crawler.model";
import { Flex } from "~/components/shared/global";
import { ExperimentOutlined } from "@ant-design/icons";

export const CrawlingList: React.FC = () => {
  const navigate = useNavigate();
  const [innerCrawlingList, setInnerCrawlingList] = useState<ICrawling[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 25,
    total: -1,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>("descend");

  const fetchCrawlings = async () => {
    if (!pagination.current || !pagination.pageSize) return;

    setIsLoading(true);

    const { docs: crawlingList, meta } = await listCrawlings({
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
      sortBy: "creationDate",
      sortOrder: sortOrder === "ascend" ? "asc" : "desc",
    });

    if (crawlingList.length) setInnerCrawlingList(crawlingList);

    if (!pagination.total || pagination.total < 0)
      setPagination({
        ...pagination,
        total: meta.total,
      });

    setIsLoading(false);
  };

  const handleClick = () => {
    navigate("/content-sources/crawling/create/");
  };

  const handleChangeTable = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ICrawling> | SorterResult<ICrawling>[]
  ) => {
    const { order } = sorter as SorterResult<ICrawling>;
    setPagination(pagination);
    setSortOrder(order);
  };

  useEffect(() => {
    fetchCrawlings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, sortOrder]);

  const columns: ColumnsType<ICrawling> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "55%",
      render: (text: string) => {
        const crawling = _.find(innerCrawlingList, { title: text });
        if (crawling) {
          return (
            <Link to={`/content-sources/crawling/${crawling.id}`}>
              {crawling.title}
            </Link>
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "title",
      key: "title",
      filters: [
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Progress",
          value: "progress",
        },
        {
          text: "Success",
          value: "success",
        },
        {
          text: "Failure",
          value: "failure",
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => value === record?.meta?.status,
      render: (text: string) => {
        const crawling = _.find(innerCrawlingList, { title: text });
        if (crawling) {
          return (
            <Tag
              color={
                crawling?.meta?.status === "success"
                  ? "success"
                  : crawling?.meta?.status === "failure"
                  ? "error"
                  : "warning"
              }
            >
              {crawling?.meta?.status.toLocaleUpperCase()}
            </Tag>
          );
        }
      },
    },
    {
      title: "Creation date",
      dataIndex: "meta",
      key: "meta",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      sorter: true,
      render: (meta: {
        creationDate: Date;
        lastModified: Date;
        status: "draft" | "published";
      }) => {
        return new Date(meta.creationDate).toLocaleDateString();
      },
    },
  ];

  return (
    <div className='basic-layout'>
      <Flex justify='space-between' align='center'>
        <h1 style={{ fontWeight: 800, fontSize: "30px", width: "100%" }}>
          Crawlings
        </h1>
        <Flex align='center' gap={8} justify='end'>
          <Button
            type='default'
            onClick={() => navigate("doi")}
            icon={<ExperimentOutlined />}
          >
            {"Crawl from DOI (experimental)"}
          </Button>
          <Button
            type='primary'
            onClick={handleClick}
            style={{ marginRight: 10 }}
          >
            {"New crawling"}
          </Button>
        </Flex>
      </Flex>
      <Table
        columns={columns}
        dataSource={innerCrawlingList.map((crawling: ICrawling) => ({
          ...crawling,
          key: crawling._id,
        }))}
        loading={isLoading}
        pagination={pagination}
        onChange={(pagination, filters, sorter) =>
          handleChangeTable(pagination, filters, sorter)
        }
        scroll={{
          x: "max-infographic",
          y: "calc(100vh - 300px)",
        }}
        sticky
      />
    </div>
  );
};
