import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();
  function handleGoBack() {
    if (location.key === "default") navigate("/");
    else navigate(-1);
  }

  return (
    <Button
      type='link'
      icon={<ArrowLeftOutlined />}
      onClick={handleGoBack}
      style={{ padding: 0, marginBottom: 24 }}
    >
      {"Back"}
    </Button>
  );
}
