import { useCallback, useEffect, useState } from "react";
import { IRedirect } from "~/model/redirect.model";
import { getRedirect } from "~/services";


export const useShortLinkQuery = (path: string) => {
  const [data, setData] = useState<IRedirect | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const redirectExists = useCallback(async (path: string) => {
    try {
      const res = await getRedirect(path);
      return Boolean(res);
    } catch (error) {
      return false;
    }
  }, []);

  const fetch = useCallback(async () => {
    try {
      setError(null);
      setLoading(true);
      const res = await getRedirect(path);
      setData(res);
    } catch (error) {
      setError(error as string);
    } finally {
      setLoading(false);
    }
  }, [path]);

  const refresh = useCallback(() => {
    setData(null);
    fetch();
  }, [fetch]);

  useEffect(() => {
    fetch();
  }, [path]);

  return { data, loading, error, redirectExists, refresh };
};
