import { useContext, useEffect, useState } from "react";
import { Flex, Spacer } from "../../../components/shared/global";
import { useNavigate, useParams } from "react-router-dom";
import {
  createProfession,
  deleteProfession,
  getProfessionById,
  updateProfession,
} from "~/services";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Table,
  notification,
} from "antd";
import {
  DeleteOutlined,
  LeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "~/context/global.context";
import LoadingLayout from "../../../components/shared/LoadingLayout";
import { languageOptions } from "~/utils/helpers";
import slugify from "slugify";
import { IProfession } from "~/model/profession.model";
import { ITag } from "~/model/tag.model";
import { SupportedLanguage } from "~/model/enum";
import columns from "./columns";

const ProfessionEdition = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { tagList } = useContext(GlobalContext);
  const medicalSpecialties = tagList.filter((t) => !t.parent);

  const [form] = Form.useForm();
  const [profession, setProfession] = useState<IProfession | null>(null);
  const [searchResults, setSearchResults] = useState<{
    search: string;
    specialties: ITag[];
  }>({ search: "", specialties: [] });

  const allSelected =
    profession?.medical_specialties.length === medicalSpecialties.length;

  useEffect(() => {
    const fetchProfession = async () => {
      if (!params.id) return null;

      const res = await getProfessionById(params.id);

      if (res) setProfession(res);
    };

    fetchProfession();
  }, [params.id]);

  const handleSearchSpecialties = (search: string) => {
    if (!search.length)
      return setSearchResults({
        search,
        specialties: [],
      });

    const results = medicalSpecialties.filter((m) =>
      m.translations.en.toLowerCase().includes(search.toLowerCase())
    );

    setSearchResults({
      search,
      specialties: results,
    });
  };

  const handleAddSpecialty = async (id: string) => {
    if (!profession) return;

    const specialties = profession.medical_specialties;

    const deleteMode = specialties.map((el) => el._id).includes(id);

    try {
      notification.info({
        icon: <LoadingOutlined />,
        key: "loadingNotification",
        message: "Loading",
        description: "Please wait while we process your request...",
      });

      const newProfession = await updateProfession(profession._id, {
        ...profession,
        medical_specialties: deleteMode
          ? [
              ...specialties
                .map((el) => el._id)
                .filter((specialtyId) => specialtyId !== id),
            ]
          : [...specialties.map((el) => el._id), id],
      });

      setProfession(newProfession);

      if (!deleteMode)
        setSearchResults({
          search: searchResults.search,
          specialties: searchResults.specialties.filter((el) => el._id !== id),
        });

      notification.close("loadingNotification");
      notification.success({
        message: `Medical specialty have been ${
          deleteMode ? "removed" : "added"
        } successfully!`,
        description: "The profession is updated with new information.",
      });
    } catch (error) {
      notification.close("loadingNotification");
      notification.error({
        message: "Error",
        description: "An error occurred while updating profession",
      });

      throw error;
    }
  };

  const handleSaveTranslations = async (
    translations: Record<SupportedLanguage, SupportedLanguage>
  ) => {
    try {
      if (profession?._id) {
        await updateProfession(profession._id, {
          ...profession,
          medical_specialties: profession.medical_specialties.map(
            (el) => el._id
          ),
          translations,
        });

        notification.success({
          message: "Success",
          description: "Profession translations saved successfully!",
        });
      } else {
        const newProf = await createProfession({
          uid: slugify(translations.en),
          translations,
        });
        notification.success({
          message: "Success",
          description: "Profession created successfully!",
        });
        navigate(`/reference-data/profession/${newProf._id}`);
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "An error occurred while saving profession translations",
      });
    }
  };

  const handleDeleteProfession = async () => {
    if (!profession || !params.id) return console.error("Profession not found");

    Modal.confirm({
      title: "Delete profession",
      content: `Are you sure you want to delete the profession ${profession.translations.en}?`,
      onOk: async () => {
        try {
          await deleteProfession(profession._id);
          setProfession(null);
          notification.success({
            message: "Success",
            description: "Profession deleted successfully!",
          });
          navigate("/reference-data/profession");
        } catch (error) {
          console.error(error);
          notification.error({
            message: "Error",
            description: "An error occurred while deleting profession",
          });
        }
      },
    });
  };

  async function handleSelectAll() {
    if (!profession) return;

    notification.info({
      icon: <LoadingOutlined />,
      key: "loadingNotification",
      message: "Loading",
      description: "Please wait while we process your request...",
    });

    const newProfession = await updateProfession(profession._id, {
      ...profession,
      medical_specialties: allSelected
        ? []
        : [...medicalSpecialties.map((el) => el._id)],
    });

    setProfession(newProfession);

    notification.close("loadingNotification");
    notification.success({
      message: `All medical specialties have been ${
        allSelected ? "removed" : "added"
      } successfully!`,
      description: "The profession is updated with new information.",
    });
  }

  if (!profession && params.id) return <LoadingLayout />;

  return (
    <div className='basic-layout'>
      <Button
        type='link'
        icon={<LeftOutlined />}
        onClick={() => navigate(-1)}
        style={{ padding: 0 }}
      >
        Back
      </Button>
      <Spacer />
      <Flex justify='space-between' align='center'>
        <div>
          <h1 onClick={() => console.log(profession)}>
            {params.id
              ? `Profession: ${profession?.translations.en}`
              : "Create profession"}
          </h1>
        </div>
        {params.id ? (
          <Button
            danger
            icon={<DeleteOutlined />}
            type='primary'
            onClick={handleDeleteProfession}
          >
            {"Delete"}
          </Button>
        ) : (
          <div />
        )}
      </Flex>
      <Spacer />

      <Card
        title={
          <h3
            onClick={() => console.log(profession?.translations)}
            style={{ margin: 0 }}
          >
            General Information
          </h3>
        }
      >
        <Form
          form={form}
          initialValues={{
            ...profession?.translations,
          }}
          onFinish={handleSaveTranslations}
        >
          {languageOptions.map(({ value, label }) => (
            <Form.Item label={label} key={value} name={value}>
              <Input
                placeholder={label}
                defaultValue={profession?.translations[value]}
              />
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              block
              type={params.id ? "default" : "primary"}
              htmlType='submit'
            >
              {params.id ? "Save translations" : "Create profession"}
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Divider />
      {params.id && (
        <Card title='Medical Specialties'>
          <Flex justify='end' gap={16}>
            <Input.Search
              allowClear
              placeholder='Search medical specialty'
              onChange={(evt) => handleSearchSpecialties(evt.target.value)}
            />
            <Button
              type='primary'
              danger={allSelected}
              onClick={handleSelectAll}
              style={{ width: 150 }}
            >
              {allSelected ? "Unselect all" : "Select all"}
            </Button>
          </Flex>
          <Divider />

          <Table
            size='small'
            columns={columns(profession, handleAddSpecialty)}
            dataSource={(searchResults.search.length
              ? searchResults.specialties
              : medicalSpecialties
            )?.map((el) => ({
              ...el,
              key: el._id,
            }))}
          />
        </Card>
      )}
    </div>
  );
};

export default ProfessionEdition;
