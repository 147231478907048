export enum LocalStorageKeys {
  ACCESS_TOKEN = "juisci-accessToken",
  REFRESH_TOKEN = "juisci-refreshToken",
  TOKEN_EXPIRATION_TIME = "juisci-expirationTime",
  VIDEO_FORMAT_FILTER = "videoFormatFilter",
  FILTERS_DRAWER = "savesFiltersDrawer",
  NECTAR_ACTIVE_TAB = "nectarActiveTab",
  SIDER_COLLAPSED = "siderCollapsed",
}

export const YES_NO_OPTIONS = [
  {
    label: "👍 Yes",
    value: true,
  },
  {
    label: "👎 No",
    value: false,
  },
];
export const MAX_UPLOAD_IMAGE = 15;

export const CITATION_FORMATS = [
  {
    label: "American Medical Association (AMA)",
    value: "american-medical-association",
  },
  {
    label: "Vancouver",
    value: "vancouver",
  },
  {
    label: "Harvard (Elvesier)",
    value: "elsevier-harvard",
  },
];
