import { Form, FormInstance, Select } from "antd";
import { useContext } from "react";
import { GlobalContext } from "~/context/global.context";
import { searchFilterOptions } from "~/utils/helpers";

type MultiTagsFieldProps = {
  form: FormInstance;
  onSelect?: (value: string[]) => void;
};

export default function MultiTagsField({
  form,
  onSelect
}: MultiTagsFieldProps) {
  const { organisationList, roomList } = useContext(GlobalContext);
  const values = Form.useWatch("tags", form);

  const options = [
    ...(organisationList ?? []).map((organisation) => ({
      label: <span>
        organisation: {organisation.name}
      </span>,
      value: `organisation:${organisation._id}`,
      name: organisation.name,
    })),
    ...(roomList?? []).map((room) => ({
      label: <span>
        room: {room.name}
      </span>,
      value: `room:${room.id}`,
      name: room.name,
    })),
  ];

  return (
    <Select
      mode='multiple'
      placeholder='Tags'
      options={options}
      value={values}
      filterOption={searchFilterOptions}
      onSelect={onSelect}
      onChange={(value) => form.setFieldsValue({ tags: value })}
    />
  );
}
