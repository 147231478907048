import {
  CheckOutlined,
  CloseOutlined,
  ExportOutlined,
  RobotOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { Link } from "react-router-dom";
import { CrawlFromDoiTaskModel } from "~/model/crawler.model";

const columns = (): ColumnsType<CrawlFromDoiTaskModel> => [
  {
    title: "Status",
    dataIndex: "task_status",
    key: "taskStatus",
    render: (taskStatus, task) => {
      let color = "green";
      let icon = <CheckOutlined />;
      const taskError =
        task.task_result.results?.error?.message ||
        task.task_result.error ||
        "";

      switch (taskStatus) {
        case "DUPLICATE":
          color = "volcano";
          icon = <WarningOutlined />;
          break;

        case "FAILED":
          color = "red";
          icon = <CloseOutlined />;
          break;

        case "SUMMARIZED":
          color = "cyan";
          icon = <RobotOutlined />;
          break;

        case "PENDING":
          color = "orange";
          icon = <SyncOutlined spin />;
          break;

        default:
          break;
      }

      return (
        <Tooltip title={taskError}>
          <Tag color={color} icon={icon}>
            {taskStatus}
          </Tag>
        </Tooltip>
      );
    },
  },
  {
    title: "Article Title",
    dataIndex: ["task_result", "results", "title"],
    key: "articleTitle",
    render: (title: string, task) => {
      const isSuccess = task.task_status === "SUCCESS";
      const articleId = task.task_result.results?.article?._id;

      return isSuccess && articleId ? (
        <Link
          to={`/content-management/article/${articleId}`}
          style={{ fontWeight: 600 }}
        >
          {title}
        </Link>
      ) : (
        <span style={{ opacity: task.task_status === "PENDING" ? 1 : 0.5 }}>
          {title || "N/A"}
        </span>
      );
    },
  },
  {
    title: "Creation Date",
    dataIndex: "task_created_at",
    key: "taskCreatedAt",
    width: 200,
    sorter: (a, b) => moment(a.task_created_at).diff(b.task_created_at),
    render: (taskCreatedAt: string) =>
      moment(taskCreatedAt)
        .utcOffset("+02:00")
        .format("DD/MM/YYYY \\a\\t HH:mm"),
  },
  {
    title: "DOI",
    dataIndex: ["task_result", "results", "doi"],
    key: "doi",
    render: (doi: string) => (
      <a
        href={`https://doi.org/${doi}`}
        target='_blank'
        rel='noreferrer'
        style={{ display: "flex", gap: 8, alignItems: "center" }}
      >
        {doi}
        <ExportOutlined />
      </a>
    ),
  },
];

export default columns;
