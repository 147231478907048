import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Skeleton, 
  Spin,
} from "antd";
import { Flex } from "~/components/shared/global";
import { useShortLinkMutation } from "~/hooks/use-short-link-mutation";
import { useShortLinkQuery } from "~/hooks/use-short-link-query";
import { useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { useQRCode, availableThemes, availableSizes } from "./use-qr-code";
import MultiTagsField from "./MultiTagsField";
import { REDIRECT_HOST } from "./config";
const randomString = (length: number) => [...crypto.getRandomValues(new Uint8Array(length))]
   .map((x,i)=>(i=x/255*61|0,String.fromCharCode(i+(i>9?i>35?61:55:48)))).join('');

const parseRedirectToUrl = (redirectTo: string | undefined, trackingParams = ['utm_campaign', 'utm_source', 'utm_medium']) => {
  const defaultTrackingParams = Object.fromEntries(trackingParams.map(param => [param, '']));
  if (!redirectTo) {
    return {
      redirectToWithoutTracking: redirectTo,
      trackingQueryParams: defaultTrackingParams,
    };
  }
  const url = new URL(redirectTo);
  const allQueryParams = Object.fromEntries(url.searchParams.entries());
  
  const trackingQueryParams = Object.fromEntries(
    Object.entries(defaultTrackingParams).map(([key]) => [key, allQueryParams[key] || ''])
  );
  
  trackingParams.forEach(param => url.searchParams.delete(param));
  
  return {
    redirectToWithoutTracking: url.toString(),
    trackingQueryParams,
  };
};

const makeRedirectToUrl = (redirectTo: string | undefined, trackingQueryParams: Record<string, string>) => {
  if (!redirectTo) {
    return redirectTo;
  }
  const url = new URL(redirectTo);
  Object.entries(trackingQueryParams).forEach(([key, value]) => {
    if (value) {
      url.searchParams.set(key, value);
    }
  });
  return url.toString();
};

const ShortLinkEdition = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const pathFromParams = params['*'];
  const isNew = !pathFromParams;
  const path = useRef<string>(pathFromParams ?? randomString(7));
  const shortLinkUrl = `https://${REDIRECT_HOST}/${path.current}`;
  const { data, loading: redirectLoading, redirectExists, refresh } = useShortLinkQuery(path.current);
  const exists = Boolean(data);
  const [pathEditable, setPathEditable] = useState<boolean>(false);
  const [qrCodeEditable, setQrCodeEditable] = useState<boolean>(false);
  const { mutate, loading: mutationLoading } = useShortLinkMutation();
  const { qrCodeEditorElement, getData: getQRCodeData, setSize, setTheme } = useQRCode(shortLinkUrl);
  const { redirectToWithoutTracking, trackingQueryParams } = parseRedirectToUrl(data?.redirectTo);
  const qrCodeImageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
      form.setFieldsValue({
        name: data?.name ?? '',
        path: path.current,
        redirectTo: redirectToWithoutTracking || '',
        qrCodeImage: data?.qrCodeImage || '',
        trackingParams: trackingQueryParams,
        tags: data?.tags || [],
      });
  }, [data]);

  // TODO: update the server to use the hash of the image to prevent re-creating a new image on each edit
  const fetchImageData = async (): Promise<Blob | null> => {
    if (!data?.qrCodeImage?.url) {
      return null;
    }
    const response = await fetch(data?.qrCodeImage?.url);
    return await response.blob();
  }

  const handleSubmit = async () => {
    if (isNew) {
      const exists = await redirectExists(path.current);
      if (exists && confirm("Short link already exists, do you want to overwrite it?") !== true) {
        return;
      }
    }
    const data = form.getFieldsValue();
    const redirectTo = makeRedirectToUrl(data.redirectTo, data.trackingParams);
    await mutate({
      path: data.path,
      name: data.name,
      redirectTo: redirectTo || null,
      qrCodeImage: qrCodeEditable ? await getQRCodeData() as Blob : await fetchImageData(),
      tags: data.tags,
    });
    refresh();
  };

  return (
    <div className='basic-layout'>
      <Flex align='center' justify='space-between'>
        <h1>
          {isNew ? "Create short link" : "Edit short link"}
        </h1>
        <Button
          type='primary'
          onClick={() => form.submit()}
        >
          {isNew ? "Create" : "Update"}
        </Button>
      </Flex>
      <Divider />
      <Spin spinning={redirectLoading || mutationLoading}>
        <Card>
          <Form
            form={form}
            layout='vertical'
            name='short_link_form'
            onFinish={handleSubmit}
          >
            <Form.Item key='name' label='Name' name='name' required>
              <Input placeholder='Name' />
            </Form.Item>
            <Form.Item key='path' label='Short URL path' required>
              <Flex align='center'>
                <span>https://{REDIRECT_HOST}/</span>
                <Form.Item name='path' noStyle>
                  <Input disabled={!pathEditable} />
                </Form.Item>
                {!exists && <Button type='link' onClick={() => setPathEditable(!pathEditable)}>{ pathEditable ? "Cancel" : "Edit" }</Button>}
              </Flex>
            </Form.Item>
            <Form.Item label='Redirects to' name='redirectTo' required>
              <Input placeholder='URL' value={redirectToWithoutTracking} />
            </Form.Item>
            <Card size="small" title="URL Parameters" style={{ marginBottom: 16 }}>
              <Form.Item name="trackingParams" noStyle>
                <Flex flexDirection="column" gap={8}>
                  {Object.entries(trackingQueryParams).map(([key]) => (
                    <Flex key={key} gap={8} align="center">
                      <Input
                        value={key}
                        style={{ width: 150 }}
                        disabled
                      />
                      <Form.Item name={['trackingParams', key]} noStyle>
                        <Input placeholder="Value" />
                      </Form.Item>
                    </Flex>
                  ))}
                </Flex>
              </Form.Item>
            </Card>
            
            <Form.Item name='tags' label='Tags'>
              <MultiTagsField form={form} />
            </Form.Item>

            <Form.Item label='QR code' name='qrCodeImage'>
              {redirectLoading ? <Skeleton.Image active /> : !qrCodeEditable ? <>
                {data?.qrCodeImage && <img src={data?.qrCodeImage?.url} ref={qrCodeImageRef} alt='QR code' />}
                <Button type='link' onClick={() => setQrCodeEditable(!qrCodeEditable)}>{!data?.qrCodeImage ? "Create QR code" : "Edit QR code"}</Button>
              </> : <>
                <Flex gap={8}>
                  {availableThemes.map((theme) => (
                    <Button key={theme} type='link' onClick={() => setTheme(theme)}>{theme}</Button>
                  ))}
                </Flex>
                <Flex gap={8}>
                  {availableSizes.map((size) => (
                    <Button key={size} type='link' onClick={() => setSize(size)}>{size}</Button>
                  ))}
                </Flex>
                {qrCodeEditorElement}
              </>}
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default ShortLinkEdition;
