import { useState } from "react";
import { UpsertRedirectDto } from "~/model/dto/redirect-dto";
import { upsertRedirect } from "~/services";

export const useShortLinkMutation = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const mutate = async (redirect: UpsertRedirectDto) => {
    try {
      setError(null);
      setLoading(true);
      const res = await upsertRedirect(redirect);
      return res;
    } catch (error) {
      setError(error as string);
    } finally {
      setLoading(false);
    }
  };

  return { mutate, loading, error };
};
