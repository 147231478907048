import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { IProfession } from "~/model/profession.model";

const columns = (
  profession: IProfession | null,
  handleAddSpecialty: (arg: string) => void
): ColumnsType<IProfession["medical_specialties"][0]> => {
  return [
    {
      title: "Name",
      dataIndex: ["translations", "en"],
      key: "name",
      render: (name, specialty) => (
        <Link
          key={specialty._id + "link1"}
          to={`/reference-data/tag/${specialty.uid}`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      width: 150,
      render: (_, specialty) => {
        const isAssociated = profession?.medical_specialties
          .map((el) => el._id)
          .includes(specialty._id);

        return (
          <Button
            key={specialty._id + "button1"}
            size='small'
            block
            danger={isAssociated}
            type={isAssociated ? "default" : "primary"}
            icon={isAssociated ? <DeleteOutlined /> : <PlusOutlined />}
            onClick={() => handleAddSpecialty(specialty._id)}
          >
            {isAssociated ? "Remove" : "Add"}
          </Button>
        );
      },
    },
  ];
};

export default columns;
