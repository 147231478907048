import { useEffect, useMemo, useRef, useState } from "react";
import QRCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  Options,
  FileExtension
} from "qr-code-styling";

const defaultOptions: Options = {
  type: 'png' as DrawType,
  margin: 10,
  qrOptions: {
    typeNumber: 0 as TypeNumber,
    mode: 'Byte' as Mode,
    errorCorrectionLevel: 'Q' as ErrorCorrectionLevel
  },
  dotsOptions: {
    color: '#000',
    type: 'square' as DotType
  },
  backgroundOptions: {
    color: '#fff',
  },
  cornersSquareOptions: {
    color: '#000',
    type: 'square' as CornerSquareType,
  },
  cornersDotOptions: {
    color: '#000',
    type: 'square' as CornerDotType,
  }
};

const juisciLogoOptions = {
  image: '/juisci-logo-tube.png',
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 4,
    crossOrigin: 'anonymous',
  },
}

export const availableThemes = ['default', 'with-logo', 'rounded', 'rounded-with-logo', 'dotted', 'dotted-with-logo'] as const;
export const availableSizes = [200, 400, 800, 1000] as const;

export type Theme = typeof availableThemes[number];
export type Size = typeof availableSizes[number];

const qrConfigs: Record<Theme, Options> = {
  'default': defaultOptions,
  'with-logo': {
    ...defaultOptions,
    ...juisciLogoOptions
  },
  'rounded': {
    ...defaultOptions,
    cornersSquareOptions: {
      ...defaultOptions.cornersSquareOptions,
      type: 'rounded' as CornerSquareType,
    },
  },
  'rounded-with-logo': {
    ...defaultOptions,
    ...juisciLogoOptions,
    cornersSquareOptions: {
      ...defaultOptions.cornersSquareOptions,
      type: 'rounded' as CornerSquareType,
    },
  },
  'dotted': {
    ...defaultOptions,
    dotsOptions: {
      ...defaultOptions.dotsOptions,
      type: 'dots' as DotType
    },
    cornersDotOptions: {
      ...defaultOptions.cornersDotOptions,
      type: undefined,
    },
    cornersSquareOptions: {
      ...defaultOptions.cornersSquareOptions,
      type: undefined,
    },
  },
  'dotted-with-logo': {
    ...defaultOptions,
    ...juisciLogoOptions,
    dotsOptions: {
      ...defaultOptions.dotsOptions,
      type: 'dots' as DotType
    },
    cornersDotOptions: {
      ...defaultOptions.cornersDotOptions,
      type: undefined,
    },
    cornersSquareOptions: {
      ...defaultOptions.cornersSquareOptions,
      type: undefined,
    },
  },
}

export const QRCodeEditor = ({ qrCode }: { qrCode: QRCodeStyling }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = '';
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  return <div ref={ref} />;
};

export function useQRCode(url: string) {
  const [size, setSize] = useState<number>(300);
  const [theme, setTheme] = useState<Theme>('default');
  const options = useMemo(() => ({
    ...qrConfigs[theme],
    data: url,
    width: size,
    height: size,
  }), [theme, url, size]);
  const imageUrlRef = useRef<string | null>(options.image || null);
  const [qrCode, setQRCode] = useState<QRCodeStyling>(new QRCodeStyling(options));

  useEffect(() => {
    if (!qrCode) return;
    if (options.image != imageUrlRef.current) {
      // qrCode.update() doesn't work when the image changes
      setQRCode(new QRCodeStyling(options));
      imageUrlRef.current = options.image || null;
      return;
    }
    qrCode.update(options);
  }, [qrCode, options]);

  const getData = async () => {
    if (!qrCode) return;
    const data = await qrCode.getRawData(options.type as FileExtension);
    return data;
  };

  const qrCodeEditorElement = useMemo(() => (
    <QRCodeEditor qrCode={qrCode} />
  ), [qrCode]);

  return {
    qrCodeEditorElement,
    getData,
    size,
    theme,
    setSize,
    setTheme,
  };
}