import { Button, Card, Form, notification, Pagination, Tag } from "antd";
import { Flex, Spacer } from "../../components/shared/global";
import { Link, useNavigate } from "react-router-dom";
import { AppstoreAddOutlined, CopyOutlined, EditOutlined, ExportOutlined, GlobalOutlined } from "@ant-design/icons";
import { IRedirect } from "~/model/redirect.model";
import { useContext, useMemo, useState } from "react";
import { GlobalContext } from "~/context/global.context";
import { useShortLinksQuery } from "~/hooks/use-short-links-query";
import MultiTagsField from "./MultiTagsField";
import { REDIRECT_HOST } from "./config";

const UrlWithEllipsis = ({ url, asLink, maxLength = 40, style }: { url: string, asLink?: boolean, maxLength?: number, style?: React.CSSProperties }) => {
  const text = url.length > maxLength ? url.slice(0, maxLength) + "[...]" : url;
  return asLink ? <a href={url} target="_blank" style={style}>{text}</a> : text;
};

const TagWithContent = ({ tag }: { tag: string }) => {
  const { organisationList, roomList } = useContext(GlobalContext);
  const [tagType, tagId] = tag.split(":");
  if (tagType === "organisation") {
    const organisation = organisationList?.find((organisation) => organisation._id === tagId);
    return <Tag color="blue">organisation: {organisation?.name}</Tag>;
  }
  if (tagType === "room") {
    const room = roomList?.find((room) => room.id === tagId);
    return <Tag color="blue">room: {room?.name}</Tag>;
  }
};

const ShortLinkList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [form] = Form.useForm();
  const tags = Form.useWatch("tags", form);
  const filters = useMemo(() => ({ tags }), [tags]);
  const { data: redirects, meta, loading } = useShortLinksQuery({ page, limit, filters });
  
  const handleCopy = (path: string) => {
    navigator.clipboard.writeText(`https://${REDIRECT_HOST}/${path}`);
    notification.success({
      message: "Copied to clipboard",
      description: `https://${REDIRECT_HOST}/${path}`,
    });
  };

  const handleEdit = (record: IRedirect) => {
    navigate(`/sensitive-data/short-links/${record.path}`);
  };

  return (
    <div className='basic-layout'>
      <Flex justify='space-between'>
        <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
          Short links
        </h1>
        <Link to={"create"}>
          <Button icon={<AppstoreAddOutlined />} type='primary'>
            {"Create short link"}
          </Button>
        </Link>
      </Flex>
      <Spacer />

      <Card
        title={
          <Flex align='center' justify='space-between' gap={20}>
            <div>{"Short links list"}</div>
            <Flex flexDirection="column" align="end">
              <div style={{ fontSize: '12px' }}>{"Filter by tags"}</div>
              <Form form={form} style={{ width: '100%' }}>
                <Form.Item name="tags">
                  <MultiTagsField form={form} />
                </Form.Item>
              </Form>
            </Flex>
          </Flex>
        }
      >
        {loading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>
        ) : (
          <>
            {(redirects ?? []).map((record: IRedirect) => (
              <Card key={record.path} style={{ marginBottom: '12px' }}>
                <Flex flexDirection="row" gap={12}>
                  <div style={{ flexBasis: '80px', border: '1px solid #e0e0e0', padding: '6px', borderRadius: '8px' }}>
                    {record.qrCodeImage && (
                        <a href={record.qrCodeImage.url} target="_blank">
                          <img width={80} src={record.qrCodeImage.url} alt="QR code" />
                        </a>
                    )}
                  </div>

                  <Flex flexDirection="column" style={{ flexBasis: '30%' }}>
                    <div style={{ fontSize: '16px', fontWeight: 600 }}>{record.name ?? 'No name'}</div>
                    <div style={{ fontSize: '10px' }}>Created: {new Date(record.createdAt).toLocaleDateString()}</div>
                  </Flex>

                  <Flex flexDirection="column">
                    <Flex flexDirection="row" align="center" gap={4}>
                      <GlobalOutlined />
                      <div style={{ fontSize: '12px' }}>{REDIRECT_HOST}/{record.path}</div>
                      <Button type="link" icon={<CopyOutlined style={{ fontSize: '12px' }} />} onClick={() => handleCopy(record.path)} />
                    </Flex>
                    
                    <Flex flexDirection="row" align="center" gap={4}>
                      <ExportOutlined />
                      <UrlWithEllipsis url={record.redirectTo} style={{ fontSize: '10px' }} asLink />
                      <Button type="link" icon={<CopyOutlined style={{ fontSize: '12px' }} />} onClick={() => handleCopy(record.redirectTo)} />
                    </Flex>

                    <Flex flexDirection="row" align="center" gap={4}>
                      {record.tags?.map((tag: string) => (
                        <TagWithContent tag={tag} />
                      ))}
                    </Flex>
                  </Flex>

                  <Flex flexDirection="row" align="center" justify="center" style={{ flexBasis: 'fit-content' }}>
                    <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)} >
                      {"Edit"}
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            ))}
            
            <Flex justify="end" style={{ marginTop: '20px' }}>
              <Pagination
                total={meta?.total || 0}
                pageSize={meta?.limit || 20}
                current={(meta?.offset || 0) + 1}
                showSizeChanger
                showTotal={(total) => `Total ${total} items`}
                onChange={(current, pageSize) => {
                  setPage(current - 1);
                  setLimit(pageSize);
                }}
              />
            </Flex>
          </>
        )}
      </Card>
    </div>
  );
};

export default ShortLinkList;
