import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  notification,
  Layout,
  Row,
  Spin,
  Col,
  Select,
  Input,
} from "antd";

import { red } from "@ant-design/colors";
import { LeftOutlined } from "@ant-design/icons";
import {
  getCongress,
  createCongress,
  deleteCongress,
  updateCongress,
  uploadCongressImage,
} from "../../services";
import { ICreateCongressDto } from "../../model/dto/congress-dto";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ImageUploader from "../../components/shared/ImageUploader";
import { ICongress } from "~/model/congress.mode";
import { getRequestErrorMessage, languageOptions } from "~/utils/helpers";
import LoadingLayout from "~/components/shared/LoadingLayout";

export const CongressEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const congressId = params.id;

  const [congressSaving, setCongressSaving] = useState<boolean>(false);
  const [innerCongress, setInnerCongress] = useState<ICongress | null>(null);

  const [form] = Form.useForm();

  const fetchCongress = async () => {
    if (congressId && !innerCongress) {
      const congress = await getCongress(congressId);
      setInnerCongress(congress);
    }
  };

  useEffect(() => {
    fetchCongress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReturn = () => {
    navigate(`/reference-data/congress/`);
  };

  const openNotificationSuccess = (entity: string) => {
    notification["success"]({
      message: `${entity} saved`,
      description: `The ${entity.toLowerCase()} was succesfully saved.`,
    });
  };

  const openNotificationDeletion = (entity: string) => {
    notification["success"]({
      message: `${entity} deleted`,
      description: `The ${entity.toLowerCase()} was succesfully deleted.`,
    });
  };

  const handleImageUpload = async (file: File) => {
    setCongressSaving(true);
    if (innerCongress) {
      const infographic = await uploadCongressImage(innerCongress._id, file);
      if (infographic) {
        setInnerCongress({
          ...innerCongress,
          image: infographic.image,
        });
        setCongressSaving(false);
      }
    } else {
      setCongressSaving(false);
    }
  };

  const handleImageDeletion = async () => {
    setCongressSaving(true);
    if (congressId) {
      const congress = await updateCongress(congressId, {
        ...innerCongress,
        image: null,
      });
      if (congress) {
        setInnerCongress(congress);
      }
    }
    setCongressSaving(false);
  };

  const handleEdition = async (values: ICreateCongressDto) => {
    setCongressSaving(true);

    try {
      const data = {
        ...values,
      };

      if (congressId) {
        const updatedCongress = await updateCongress(congressId, data);
        setInnerCongress(updatedCongress);
      } else {
        const createdCongress = await createCongress(data);
        if (createdCongress)
          navigate(`/reference-data/congress/${createdCongress._id}`, {
            replace: true,
          });
        setInnerCongress(createdCongress);
      }
      openNotificationSuccess("Congress");
    } catch (error) {
      notification.error({
        message: "An error occurred while saving the congress",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setCongressSaving(false);
    }
  };

  const handleDeletion = async () => {
    setCongressSaving(true);
    try {
      if (!congressId) return;
      const deletedCongress = await deleteCongress(congressId);
      openNotificationDeletion("Congress");
      if (deletedCongress) {
        navigate("/reference-data/congress/");
      }
    } catch (error) {
      notification.error({
        message: "An error occurred while deleting the congress",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setCongressSaving(false);
    }
  };

  if (!innerCongress && !!congressId) return <LoadingLayout />;

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px" }}>
        <Row>
          <div>
            <Button
              onClick={handleReturn}
              shape='circle'
              style={{ marginRight: 20 }}
            >
              <LeftOutlined />
            </Button>
          </div>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
            {congressId ? "Edit congress" : "Create congress"}
          </h1>
        </Row>
        <div className='site-layout-content'>
          <Spin spinning={congressSaving} tip='Congress saving...'>
            <Form
              form={form}
              name='congress_edition'
              layout='vertical'
              initialValues={{ ...innerCongress }}
              onFinish={handleEdition}
            >
              <Row
                style={{
                  paddingBottom: "20px",
                  borderBottom: "1px solid #d9d9d9",
                }}
                justify='space-between'
                gutter={20}
              >
                <Col span={12}>
                  <Form.Item name='language' label='Language' required>
                    <Select
                      options={languageOptions}
                      placeholder='Select language'
                    />
                  </Form.Item>
                  <Form.Item name='name' label='Name' required>
                    <Input placeholder='Congress name' />
                  </Form.Item>
                  <Form.Item name='description' label='Description' required>
                    <Input.TextArea
                      placeholder='Description'
                      autoSize={{
                        minRows: 5,
                        maxRows: 10,
                      }}
                    />
                  </Form.Item>
                  <Form.Item name='website' label='Website'>
                    <Input placeholder='Website' />
                  </Form.Item>
                </Col>
                {congressId && innerCongress ? (
                  <Col span={12}>
                    <Form.Item label='Congress Logo' style={{ marginTop: 16 }}>
                      <ImageUploader
                        images={
                          innerCongress?.image ? [innerCongress.image] : []
                        }
                        inputLegendImage={null}
                        deleteFile={() => handleImageDeletion()}
                        onChange={(file) => handleImageUpload(file)}
                      />
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>

              <Row justify='end'>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='congress-form-button'
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    {congressId ? "Save congress" : "Create congress"}
                  </Button>
                  {congressId ? (
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: red.primary,
                        marginLeft: "20px",
                      }}
                      onClick={handleDeletion}
                    >
                      {"Delete congress"}
                    </Button>
                  ) : null}
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </div>
      </Layout.Content>
    </Layout>
  );
};
